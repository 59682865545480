html {
  scroll-behavior: smooth;
}

.animated {
  animation-duration: 500ms;
}

.animated--slow {
  animation-duration: 800ms;
}

.hidden {
  display: none;
  visibility: hidden;
}
